<template>
  <validation-observer
    #default="{ handleSubmit, invalid }"
    ref="refFormObserver"
  >
    <b-form
      @submit.prevent="handleSubmit(onSubmit)"
      @reset.prevent="resetForm"
    >
      <b-row class="mb-2">
        <b-col cols="12">
          <div class="demo-inline-spacing">
            <b-form-radio
              v-model="item.useDefaultConfiguration"
              name="useDefaultConfiguration"
              :value="true"
              class="custom-control-primary"
            >
              {{ t('Email mặc định của Resident') }}
            </b-form-radio>

            <b-form-radio
              v-model="item.useDefaultConfiguration"
              name="useDefaultConfiguration"
              :value="false"
              class="custom-control-primary"
            >
              {{ t('Email tùy chỉnh') }}
            </b-form-radio>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="item.useDefaultConfiguration === false">
        <b-col cols="6">
          <validation-provider
            #default="validationContext"
            name="dispayName"
            rules="required"
          >
            <b-form-group>
              <template v-slot:label>
                {{ t('Tên hiển thị') }} <span class="text-danger">(*)</span>
              </template>
              <b-input-group>
                <b-form-input
                  id="dispayName"
                  v-model="item.displayName"
                  placeholder="Resident"
                  trim
                  :state="getValidationState(validationContext)"
                />
              </b-input-group>
              <small class="text-danger">{{
                validationContext.errors[0]
              }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="6">
          <validation-provider
            #default="validationContext"
            name="displayAddress"
            rules="required|email"
          >
            <b-form-group>
              <template v-slot:label>
                {{ t('Địa chỉ hiển thị') }} <span class="text-danger">(*)</span>
              </template>
              <b-input-group>
                <b-form-input
                  id="displayAddress"
                  v-model="item.displayAddress"
                  placeholder="info@resident.vn"
                  trim
                  :state="getValidationState(validationContext)"
                />
              </b-input-group>
              <small class="text-danger">{{
                validationContext.errors[0]
              }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>

        <!-- submit -->
      </b-row>
      <b-row v-if="item.useDefaultConfiguration === false">
        <b-col cols="6">
          <validation-provider
            #default="validationContext"
            name="host"
            rules="required"
          >
            <b-form-group>
              <template v-slot:label>
                {{ t('Host') }} <span class="text-danger">(*)</span>
              </template>
              <b-input-group>
                <b-form-input
                  id="host"
                  v-model="item.host"
                  placeholder="smtp.gmail.com"
                  trim
                  :state="getValidationState(validationContext)"
                />
              </b-input-group>
              <small class="text-danger">{{
                validationContext.errors[0]
              }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="6">
          <validation-provider
            #default="validationContext"
            name="port"
            rules="required"
          >
            <b-form-group>
              <template v-slot:label>
                {{ t('Port') }} <span class="text-danger">(*)</span>
              </template>
              <b-input-group>
                <b-form-input
                  id="port"
                  v-model="item.port"
                  placeholder="587"
                  trim
                  :state="getValidationState(validationContext)"
                />
              </b-input-group>
              <small class="text-danger">{{
                validationContext.errors[0]
              }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>

        <!-- submit -->
      </b-row>
      <b-row v-if="item.useDefaultConfiguration === false">
        <b-col cols="6">
          <validation-provider
            #default="validationContext"
            name="username"
            rules="required"
          >
            <b-form-group>
              <template v-slot:label>
                {{ t('Tên đăng nhập') }} <span class="text-danger">(*)</span>
              </template>
              <b-input-group>
                <b-form-input
                  id="username"
                  v-model="item.username"
                  placeholder="info@resident.vn"
                  trim
                  :state="getValidationState(validationContext)"
                />
              </b-input-group>
              <small class="text-danger">{{
                validationContext.errors[0]
              }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="6">
          <validation-provider
            #default="{ errors }"
            name="Mật khẩu"
            rules="required"
          >
            <b-form-group>
              <template v-slot:label>
                {{ t('Mật khẩu') }} <span class="text-danger">(*)</span>
              </template>
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >

                <b-form-input
                  id="password"
                  v-model="item.password"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  :state="errors.length > 0 ? false : null"
                  name="password"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIcon"
                    class="cursor-pointer"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>

          </validation-provider>
        </b-col>

        <!-- submit -->
      </b-row>
      <b-button
        class="mt-2"
        variant="primary"
        block
        type="submit"
        :disabled="invalid"
      >
        {{ t('Lưu') }}
      </b-button>
      <!-- <div v-if="item.useDefaultConfiguration === false">
        <hr>

        <b-form-group :label="t('Kiểm tra cấu hình bằng việc gửi email test')">
          <b-input-group>
            <b-form-input placeholder="phongnn@resident.vn" />
            <b-input-group-append>
              <b-button variant="outline-primary">
                <feather-icon
                  icon="SendIcon"
                  class="mr-50"
                />
                {{ t('Gửi mail test') }}
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div> -->

    </b-form>
  </validation-observer>
</template>

<script>
import {
  BForm, BRow, BCol, BFormGroup, BFormInput, BInputGroup, BButton, BInputGroupAppend, BFormRadio,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, email } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import useJwt from '@/auth/jwt/useJwt';
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue';
import toast from '@/utils/toast';
import { useUtils as useI18nUtils } from '@core/libs/i18n';

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BFormRadio,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BButton,
    BInputGroupAppend,
    ValidationObserver,
    ValidationProvider,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      item: {},
      // validation
      required,
      email,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
  },
  setup() {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();
    const toastification = toast();
    const { t } = useI18nUtils();
    return {

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      toastification,
      t,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      useJwt.getEmailConfiguration()
        .then(response => {
          this.item = response.data;
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Thông báo`,
              icon: 'UserIcon',
              variant: 'danger',
              text: `Có lỗi xảy ra khi lấy thông tin cài đặt. Vui lòng thử lại sau`,
            },
          });
        });
    },
    onSubmit() {
      useJwt.updateEmailConfiguration(this.item).then(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Thông báo`,
            icon: 'UserIcon',
            variant: 'success',
            text: `Thông tin đã được cập nhật thành công`,
          },
        });
      }).catch(error => {
        if (error.response && error.response.status === 422 && error.response.data && error.response.data.errors) {
          this.$refs.refFormObserver.setErrors(error.response.data.errors);
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Thông báo',
              text: error.response.data.message ? error.response.data.message : 'Có lỗi xảy ra, vui lòng thử lại sau',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        }
      });
    },
  },
};
</script>
