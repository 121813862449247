<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>{{ t('Cài đặt') }}</b-card-title>
    </b-card-header>
    <b-card-body>
      <b-tabs>
        <b-tab>
          <template #title>
            <feather-icon icon="SettingsIcon" />
            <span>{{ t('Cài đặt chung') }}</span>
          </template>

          <setting-tab-general />
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="EditIcon" />
            <span>{{ t('Hợp đồng') }}</span>
          </template>

          <setting-tab-contract />
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="FileTextIcon" />
            <span>{{ t('Hóa đơn') }}</span>
          </template>

          <setting-tab-invoice />
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="FolderPlusIcon" />
            <span>{{ t('Thu chi') }}</span>
          </template>

          <setting-tab-income-expense />
        </b-tab>

        <b-tab>
          <template #title>
            <feather-icon icon="BellIcon" />
            <span>{{ t('Thông báo') }}</span>
          </template>
          <setting-tab-notification />
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="MailIcon" />
            <span>{{ t('Email') }}</span>
          </template>
          <setting-tab-email />
        </b-tab>
      </b-tabs>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BTab, BTabs, BCardTitle, BCardHeader,
} from 'bootstrap-vue';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import SettingTabGeneral from './tabs/SettingTabGeneral.vue';
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import SettingTabContract from './tabs/SettingTabContract.vue';
import SettingTabInvoice from './tabs/SettingTabInvoice.vue';
import SettingTabIncomeExpense from './tabs/SettingTabIncomeExpense.vue';
import SettingTabNotification from './tabs/SettingTabNotification.vue';
import SettingTabEmail from './tabs/SettingTabEmail.vue';

export default {
  components: {
    BCard,
    BCardBody,
    BTab,
    BTabs,
    BCardTitle,
    BCardHeader,
    SettingTabGeneral,
    SettingTabContract,
    SettingTabInvoice,
    SettingTabIncomeExpense,
    SettingTabNotification,
    SettingTabEmail
  },
  setup() {
    const { t } = useI18nUtils();
    return { t };
  },
};
</script>
