<template>
  <validation-observer
    #default="{ handleSubmit, invalid }"
    ref="refFormObserver"
  >
    <b-form
      @submit.prevent="handleSubmit(onSubmit)"
      @reset.prevent="resetForm"
    >
      <b-row>
        <b-col cols="12">
          <span>{{ t('Tự động duyệt chỉ số công tơ') }}</span>
          <div class="demo-inline-spacing">
            <b-form-radio
              v-model="data.autoApproveMeterLog"
              name="autoApproveMeterLog"
              :value="true"
              class="custom-control-primary"
            >
              {{ t('Duyệt tự động') }}
            </b-form-radio>

            <b-form-radio
              v-model="data.autoApproveMeterLog"
              name="autoApproveMeterLog"
              :value="false"
              class="custom-control-primary"
            >
              {{ t('Duyệt thủ công') }}
            </b-form-radio>
          </div>
        </b-col>
      </b-row>
      <hr>
      <b-row class="mt-2">
        <b-col cols="12">
          <span>{{ t('Mẫu hóa đơn') }}</span>
          <div class="demo-inline-spacing">
            <b-form-radio
              v-model="data.applyNewInvoiceTemplate"
              name="applyNewInvoiceTemplate"
              :value="true"
              class="custom-control-primary"
            >
              {{ t('Sử dụng mẫu mới') }}
            </b-form-radio>

            <b-form-radio
              v-model="data.applyNewInvoiceTemplate"
              name="applyNewInvoiceTemplate"
              :value="false"
              class="custom-control-primary"
            >
              {{ t('Sử dụng mẫu cũ') }}
            </b-form-radio>
          </div>
        </b-col>
      </b-row>
      <hr>
      <b-row class="mt-2">
        <b-col cols="12">
          <span>{{ t('Tự động duyệt hóa đơn') }}</span>
          <div class="demo-inline-spacing">
            <b-form-radio
              v-model="data.autoApproveInvoice"
              name="autoApproveInvoice"
              :value="true"
              class="custom-control-primary"
            >
              {{ t('Duyệt tự động') }}
            </b-form-radio>

            <b-form-radio
              v-model="data.autoApproveInvoice"
              name="autoApproveInvoice"
              :value="false"
              class="custom-control-primary"
            >
              {{ t('Duyệt thủ công') }}
            </b-form-radio>
          </div>
        </b-col>
      </b-row>
      <hr>
      <b-row class="mt-2">
        <b-col cols="12">
          <span>{{ t('Sử dụng hệ số trong hóa đơn? (Nếu không thì hệ số mặc định là 1)') }}</span>
          <div class="demo-inline-spacing">
            <b-form-radio
              v-model="data.enableCoefficient"
              name="enableCoefficient"
              :value="true"
              class="custom-control-primary"
            >
              {{ t('Có') }}
            </b-form-radio>

            <b-form-radio
              v-model="data.enableCoefficient"
              name="enableCoefficient"
              :value="false"
              class="custom-control-primary"
            >
              {{ t('Không') }}
            </b-form-radio>
          </div>
        </b-col>
      </b-row>
      <hr>
      <b-row class="mt-2">
        <b-col cols="12">
          <span>{{ t('Tự động tính toán hệ số dựa trên ngày bắt đầu và kết thúc của dịch vụ') }}</span>
          <div class="demo-inline-spacing">
            <b-form-radio
              v-model="data.autoCalculateCoefficientByDate"
              name="autoCalculateCoefficientByDate"
              :value="true"
              class="custom-control-primary"
            >
              {{ t('Có') }}
            </b-form-radio>

            <b-form-radio
              v-model="data.autoCalculateCoefficientByDate"
              name="autoCalculateCoefficientByDate"
              :value="false"
              class="custom-control-primary"
            >
              {{ t('Không') }}
            </b-form-radio>
          </div>
        </b-col>
      </b-row>
      <hr>
      <b-row class="mt-2">
        <b-col cols="12">
          <span>{{ t('Tự động tính toán chu kỳ thời gian tiền dịch vụ') }}</span>
          <div class="demo-inline-spacing">
            <b-form-radio
              v-model="data.serviceFeeCalculateType"
              name="serviceFeeCalculateType"
              :value="'in-month'"
              class="custom-control-primary"
            >
              {{ t('Theo chu kỳ trong tháng') }}
            </b-form-radio>

            <b-form-radio
              v-model="data.serviceFeeCalculateType"
              name="serviceFeeCalculateType"
              :value="'by-payment-day'"
              class="custom-control-primary"
            >
              {{ t('Theo chu kỳ từ ngày chốt tiền') }}
            </b-form-radio>
            <b-form-radio
              v-model="data.serviceFeeCalculateType"
              name="serviceFeeCalculateType"
              :value="'full'"
              class="custom-control-primary"
            >
              {{ t('Theo chu kỳ từ ngày bắt đầu tính tiền') }}
            </b-form-radio>
          </div>
        </b-col>
      </b-row>
      <hr>
      <b-row class="mt-2">
        <b-col cols="12">
          <span>{{ t('Cách chia tỷ lệ trong TH lẻ ngày') }}</span>
          <div class="demo-inline-spacing">
            <b-form-radio
              v-model="data.calculateRentPriceRule"
              name="calculateRentPriceRule"
              :value="1"
              class="custom-control-primary"
            >
              {{ t('Tùy theo tổng ngày trong tháng') }}
            </b-form-radio>

            <b-form-radio
              v-model="data.calculateRentPriceRule"
              name="calculateRentPriceRule"
              :value="2"
              class="custom-control-primary"
            >
              {{ t('Chia cố định cho 30 ngày') }}
            </b-form-radio>

          </div>
        </b-col>
      </b-row>
      <hr>
      <b-row class="mt-2">
        <b-col cols="12">
          <span>{{ t('Hạn thanh toán hóa đơn') }}</span>
          <div class="demo-inline-spacing">
            <b-form-radio
              v-model="data.invoiceDueDateType"
              name="invoiceDueDateType"
              :value="1"
              class="custom-control-primary"
            >
              {{ t('Ngày cố định trong tháng') }}
            </b-form-radio>

            <b-form-radio
              v-model="data.invoiceDueDateType"
              name="invoiceDueDateType"
              :value="2"
              class="custom-control-primary"
            >
              {{ t('X ngày kể từ ngày lập') }}
            </b-form-radio>
            <b-form-radio
              v-model="data.invoiceDueDateType"
              name="invoiceDueDateType"
              :value="3"
              class="custom-control-primary"
            >
              {{ t('Theo ngày chốt của hợp đồng') }}
            </b-form-radio>
          </div>
        </b-col>
      </b-row>
      <b-row
        v-if="parseInt(data.invoiceDueDateType, 10) !== 3"
        class="mt-1"
      >
        <b-col>
          <validation-provider
            #default="validationContext"
            name="Số ngày"
            rules="required|between:1,31"
          >
            <number-input
              v-model="data.invoiceDueDateValue"
              :label="t('Nhập ngày')"
              :default-value="data.invoiceDueDateValue"
              :state="getValidationState(validationContext)"
              :error="validationContext.errors[0]"
            />
          </validation-provider>

        </b-col>
      </b-row>
      <hr>
      <b-row class="mt-2">
        <b-col cols="12">
          <span>{{ t('Bật tính năng tự lập hóa đơn đặt cọc') }}</span>
          <div class="demo-inline-spacing">
            <b-form-radio
              v-model="data.enableReservationInvoice"
              name="enableReservationInvoice"
              :value="true"
              class="custom-control-primary"
            >
              {{ t('Có') }}
            </b-form-radio>

            <b-form-radio
              v-model="data.enableReservationInvoice"
              name="enableReservationInvoice"
              :value="false"
              class="custom-control-primary"
            >
              {{ t('Không') }}
            </b-form-radio>
          </div>
        </b-col>
      </b-row>
      <hr>
      <b-row class="mt-2">
        <b-col cols="12">
          <span>{{ t('Tự động sinh hóa đơn tiền nhà kỳ tiếp theo') }}</span>
          <div class="demo-inline-spacing">
            <b-form-radio
              v-model="data.autoGenerateLeaseInvoice"
              name="autoGenerateLeaseInvoice"
              :value="true"
              class="custom-control-primary"
            >
              {{ t('Bật') }}
            </b-form-radio>

            <b-form-radio
              v-model="data.autoGenerateLeaseInvoice"
              name="autoGenerateLeaseInvoice"
              :value="false"
              class="custom-control-primary"
            >
              {{ t('Tắt') }}
            </b-form-radio>

          </div>
        </b-col>
      </b-row>
      <b-row
        v-if="data.autoGenerateLeaseInvoice"
        class="mt-1"
      >
        <b-col>
          <validation-provider
            #default="validationContext"
            name="Tạo hóa đơn trước bao nhiêu ngày"
            rules="required|between:0,60"
          >
            <number-input
              v-model="data.autoGenerateLeaseInvoiceBeforeDays"
              :label="t('Tạo hóa đơn trước bao nhiêu ngày')"
              :default-value="data.autoGenerateLeaseInvoiceBeforeDays"
              :state="getValidationState(validationContext)"
              :error="validationContext.errors[0]"
            />
          </validation-provider>

        </b-col>
      </b-row>

      <b-button
        class="mt-2"
        variant="primary"
        block
        type="submit"
        :disabled="invalid"
      >
        {{ t('Lưu') }}
      </b-button>

    </b-form>
  </validation-observer>
</template>

<script>
import {
  BForm, BRow, BCol, BButton, BFormRadio,
} from 'bootstrap-vue';
import { required, between } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import useJwt from '@/auth/jwt/useJwt';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import NumberInput from '@/views/components/NumberInput.vue';
import { updateUserConfiguration } from '@/auth/utils';
import { useUtils as useI18nUtils } from '@core/libs/i18n';

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    ValidationObserver,
    ValidationProvider,
    NumberInput,
    BFormRadio,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      data: {

        autoApproveInvoice: false,
        enableCoefficient: false,
        invoiceDueDateType: 2,
        invoiceDueDateValue: 5,
        autoCalculateCoefficientByDate: false,
        serviceFeeCalculateType: 'in-month',
        applyNewInvoiceTemplate: false,
        calculateRentPriceRule: 1,
        enableReservationInvoice: false,
        autoGenerateLeaseInvoice: false,
        autoGenerateLeaseInvoiceBeforeDays: 0,
      },

      // validation
      required,
      between,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
  },
  setup() {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const { t } = useI18nUtils();
    return {

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      t,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      useJwt.getUserConfiguration()
        .then(response => {
          updateUserConfiguration(response.data);
          this.data = response.data;
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Thông báo`,
              icon: 'UserIcon',
              variant: 'danger',
              text: `Có lỗi xảy ra khi lấy thông tin cài đặt. Vui lòng thử lại sau`,
            },
          });
        });
    },

    onSubmit() {
      const data = new FormData();
      data.append('autoApproveInvoice', this.data.autoApproveInvoice);
      data.append('enableCoefficient', this.data.enableCoefficient);
      data.append('invoiceDueDateType', this.data.invoiceDueDateType);
      data.append('invoiceDueDateValue', this.data.invoiceDueDateValue);
      data.append('autoCalculateCoefficientByDate', this.data.autoCalculateCoefficientByDate);
      data.append('serviceFeeCalculateType', this.data.serviceFeeCalculateType);
      data.append('applyNewInvoiceTemplate', this.data.applyNewInvoiceTemplate);
      data.append('calculateRentPriceRule', this.data.calculateRentPriceRule);
      data.append('enableReservationInvoice', this.data.enableReservationInvoice);
      data.append('autoGenerateLeaseInvoice', this.data.autoGenerateLeaseInvoice);
      data.append('autoGenerateLeaseInvoiceBeforeDays', this.data.autoGenerateLeaseInvoiceBeforeDays);

      useJwt.updateUserConfiguration(data).then(response => {
        updateUserConfiguration(response.data);
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Thông báo`,
            icon: 'UserIcon',
            variant: 'success',
            text: `Thông tin đã được cập nhật thành công`,
          },
        });
      })
        .catch(error => {
          if (error.response && error.response.status === 422 && error.response.data && error.response.data.errors) {
            this.$refs.refFormObserver.setErrors(error.response.data.errors);
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Thông báo',
                text: error.response.data.message ? error.response.data.message : 'Có lỗi xảy ra, vui lòng thử lại sau',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            });
          }
        });
    },
  },
};
</script>
